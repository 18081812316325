import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Bookmark } from '../models/bookmark.model';
import pick from 'lodash-es/pick';
import { ParamsService } from './params.service';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  readonly permitted_attributes = [
    'bookmarkable_date',
    'description',
    'bookmarkable_type',
    'bookmarkable_id'
  ];

  constructor(private http: HttpClient,
              private paramsService: ParamsService) { }


  index(userId?: number): any {
    let params = new HttpParams();
    if (userId) {
      params = params.set('user_id', `${userId}`);
    }

    return this.http.get<Bookmark[]>('/bookmarks', { params, observe: 'response' }).pipe();
  }

  delete(id: number) {
    return this.http.delete(`/bookmarks/${id}`);
  }

  save(record: Bookmark): any {
    const bookmark = new Bookmark().fromJson(pick(record, this.permitted_attributes));
    if (record.id) {
      return this.http.put(`/bookmarks/${record.id}`, { bookmark });
    } else {
      return this.http.post('/bookmarks', { bookmark });
    }
  }

}
